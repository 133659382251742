import React from "react"
import { graphql } from 'gatsby';
import BannersPartners from '../components/images/banner-partners.jpg'
import loadable from '@loadable/component'
import  'bootstrap/dist/css/bootstrap.min.css';
const Footer = loadable(() => import('../components/Footer'))
const Nav = loadable(() => import ('../components/Nav'))
/* const TopNav = loadable(() => import ('../components/TopNav')) */

function PartnerDetail({pageContext}){
    console.log(pageContext.image, "page image")
    return(
        <>
  {/*       <TopNav/> */}
      <Nav/>
      <section className=" min-height-400px cover-background" style={{backgroundImage: `url(${BannersPartners})`, marginTop: "114px"}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <h3 className="inner-banner-title text-white">Discover Dezyne together<br/>with our partners</h3>
                    </div> 
                    <div className="col-12 col-lg-4" data-wow-delay="0.2s"></div>
                </div>
            </div>
        </section>
        <section className="no-padding-tb">
            <div className="container">
                <div className="col-12">
                        <img src={pageContext.image} alt=""/>
                    </div>
                </div>                               
                    <div className="col-12">
            </div>
        </section>
        <section className="no-padding-top padding-70px-bottom">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8 md-margin-50px-bottom sm-margin-30px-bottom" dangerouslySetInnerHTML={{__html: pageContext.body}}/>
                    
                    <div className="col-12 col-lg-4">
                        <div className="bg-light-gray border-all padding-25px-all">
                            <h5 className="title-section-alt alt-font text-dark-gray letter-spacing-1">Contact information</h5>
                            <div
      dangerouslySetInnerHTML={{__html: pageContext.address}}
    />
    <p><a class="btn btn-green border-radius-5 btn-medium no-margin-lr letter-spacing-2" href="/Partners" target="_self" rel="noopener noreferrer">Back</a></p>

                        </div>                 
                    </div>
                </div>                               
            </div>
        </section>  
<Footer/>
</>)
 }
export default PartnerDetail

graphql`
query PartnersQuery {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(/Partners/)/"}}) {
        edges {
          node {
            frontmatter {
              name,
              category,
              image,
              address
            }
            rawMarkdownBody
          }
        }
    }
}
`